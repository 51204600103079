<template>
    <div class="half-block_modal modal-bordered">
        <div class="half-block_modal_header" :style="{background: `url(/assets/img/banner${ index }.png)`}"></div>
        <div class="half-block_modal_body">
            <TextContainer
                size="17px"
                :text="`adv_banner${ index }_content_text`"
                :title="`adv_banner${ index }_content_title`">

              <div class="half-block_modal_btn">
                <ProjectButton
                    variant="app"
                    radius="10"
                    @click.native="onBtnClick">
                  <div class="w-100" v-html="$content('header_registerFormSubmit')"></div>
                </ProjectButton>
              </div>

            </TextContainer>

        </div>
    </div>
</template>

<script>
import TextContainer from "@/components/TextContainer";
import ProjectButton from "../Buttons/ProjectButton";
export default {
    name: "HalfBlockModal",
    components: {ProjectButton, TextContainer},
    computed: {
        index() {
            return this.$store.getters.activeModalParams.index
        }
    },
    methods: {
        onBtnClick() {
            this.$modal.close()
                .then(() => this.$store.dispatch('togglePopoverWithForm', {
                    popover: 'auth',
                    form: 'registration'
                }))
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .half-block_modal {
        overflow-x: hidden;
        background: white;
        padding-bottom: 25px;

        &_btn {
            width: 394px !important;
            margin: 0 auto;
          margin-top: 43px;

            @include only-xs {
                width: 100% !important;
                padding: 0 15px;
            }

            & button {
                width: 100%;
            }
        }

        &_header {
            height: 207px;
            background-size: cover !important;
            background-position: center center !important;
        }

        &_body {
            //padding: 45px 45px 25px 45px;
            padding: 45px 18px 20px 45px;
            height: 345px;
            background: white;

            @include only-xs {
                padding: 30px 20px;
            }


          .text-container {
            &_content {

              & h1 {
                margin-bottom: 30px;
                text-align: start;
              }

              & p {
                font-family: Montserrat-Medium, sans-serif;
                font-size: 16px;
                color: #595959;
              }
            }
          }
        }
    }
</style>